import { CommonProps, FacebookPixelProps } from '../types';

export default function facebookPixel(
  common: CommonProps,
  facebookPixel: FacebookPixelProps,
) {
  return {
    content: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');

${getInit(common, facebookPixel)}`,
    fallback: `<img
        height="1"
        width="1"
        style="display: none;"
        src="https://www.facebook.com/tr?id=${facebookPixel.pixelId}&ev=PageView&noscript=1"/>`,
  };
}

function getInit({ product }: CommonProps, { pixelId }: FacebookPixelProps) {
  switch (product) {
    case 'dermatica':
      return `
var fbqOpts = {};
if (typeof currentUser !== "undefined" && currentUser && currentUser.username) {
  fbqOpts.em = currentUser.username;

  if (currentUser.details.birthday) {
    fbqOpts.db = '' + currentUser.details.birthday[0] + currentUser.details.birthday[1] + currentUser.details.birthday[2]
  }

  if (currentUser.details.phoneNumber) {
    fbqOpts.ph = currentUser.details.phoneNumber;
  }
}

fbq('init', '${pixelId}', fbqOpts);
fbq('track', 'PageView');
`;
    case 'medexpress':
      return `
fbq('init', '${pixelId}');
fbq('track', 'PageView');
`;
    default:
      return '';
  }
}
