import { JobsLoader } from '../../types';

const GREENHOUSE_JOB_DETAILS_URL =
  'https://boards-api.greenhouse.io/v1/boards/heliosx/jobs?content=true';

interface GreenHouseJobsData {
  jobs: GreenHouseJob[];
}

interface GreenHouseJob {
  absolute_url: string;
  title: string;
  departments: {
    name: string;
  }[];
  offices: {
    name: string;
    location: string;
  }[];
  location: {
    name: string;
  };
  metadata: {
    remote: boolean;
  };
}

type DepartmentSortedJobs = Record<string, GreenHouseJob[]>;

const greenhouseJobsLoader: JobsLoader = async ({ targetId, classNames }) => {
  function createDepartmentSection(department: string, jobs: GreenHouseJob[]) {
    const el = document.createElement('div');
    el.className = classNames.section;

    const headingEl = document.createElement('h5');
    headingEl.className = classNames.heading;
    headingEl.innerText = department;

    const jobsEl = document.createElement('div');
    jobsEl.className = classNames.jobs;

    jobs.forEach((job) => {
      const jobEl = createJobListing(job);
      jobsEl.appendChild(jobEl);
    });

    el.appendChild(headingEl);
    el.appendChild(jobsEl);
    return el;
  }

  function createJobListing(job: GreenHouseJob) {
    const title = sanitizeTitle(job.title);
    const el = document.createElement('a');
    el.className = classNames.job;
    el.title = title;
    el.href = job.absolute_url;

    const titleEl = document.createElement('div');
    titleEl.className = classNames.jobTitle;
    titleEl.innerText = title;

    const locationEl = document.createElement('span');
    locationEl.className = classNames.jobLocation;
    locationEl.innerText = buildLocationText(job);

    el.appendChild(titleEl);
    el.appendChild(locationEl);
    return el;
  }

  function sanitizeTitle(title: string) {
    return title.replace(/ [-|].*$/gi, '');
  }

  function buildLocationText({ metadata, offices, location }: GreenHouseJob) {
    const fullLocation = offices[0].location.split(', ');
    const isRemote = metadata?.remote;
    const isUK = fullLocation.includes('United Kingdom');
    const isUS = fullLocation.includes('United States');
    const state = isUS ? fullLocation[0] : null;

    const remote = `Remote & Hybrid`;

    switch (true) {
      case isRemote && isUK:
        return `${remote} - UK & Europe`;
      case isRemote && isUS:
        return state ? `${remote} - ${state}, USA` : `${remote} - USA`;
      case isRemote && !isUK && !isUS:
        return `${remote} - ${location.name}`;
      case !isRemote && isUK:
        return `${location.name}, UK`;
      case !isRemote && !isUS && !isUK:
        return location.name;
      case !isRemote && isUS:
        return state ? `${state}, USA` : 'USA';
      default:
        return '';
    }
  }

  const req = await fetch(GREENHOUSE_JOB_DETAILS_URL);
  const data = (await req.json()) as GreenHouseJobsData;

  const byDepartment = data.jobs.reduce((out, job) => {
    const department = out[job.departments[0].name] || [];

    return {
      ...out,
      [job.departments[0].name]: [...department, job],
    };
  }, {} as DepartmentSortedJobs);

  const target = document.getElementById(targetId);

  if (target) {
    target.className = classNames.container;

    Object.entries(byDepartment).forEach(([department, jobs]) => {
      if (!jobs.length) return;

      const el = createDepartmentSection(department, jobs);
      target.appendChild(el);
    });
  }
};

export default greenhouseJobsLoader;
