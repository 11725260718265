import amplitude from './script/amplitude';
import userIdCookies from './script/userIdCookies';
import googleTagManager from './script/googleTagManager';
import googleAnalytics from './script/googleAnalytics';
import hotjar from './script/hotjar';
import googleRemarketing from './script/googleRemarketing';
import bingAnalytics from './script/bingAnalytics';
import facebookPixel from './script/facebookPixel';
import redditPixel from './script/redditPixel';
import snapPixel from './script/snapPixel';
import tikTokPixel from './script/tikTokPixel';
import vwo from './script/vwo';
import getCookie from './script/getCookie';
import braze from './script/braze';
import workableJobsLoader from './util/workable/workableJobsLoader';
import greenhouseJobsLoader from './util/greenhouse/greenhouseJobsLoader';

function log(message: string) {
  console.log(
    `@heliosx/webflow-integrations@${process.env.VERSION}: ${message}`,
  );
}

function script(content: string) {
  const s = document.createElement('script');
  s.innerHTML = content;
  return s;
}

function noscript(content: string) {
  const s = document.createElement('noscript');
  s.innerHTML = content;
  return s;
}

function scriptExternal(src: string) {
  const s = document.createElement('script');
  s.src = src;
  return s;
}

function appendHead(node: Node) {
  document.head.appendChild(node);
}

function appendBody(node: Node) {
  document.body.appendChild(node);
}

function bootstrapAnalytics() {
  appendHead(script(getCookie()));
  appendHead(script(userIdCookies()));

  const c = window.analytics;

  if (!c || !c.common) {
    return log('Missing analytics config, aborting.');
  }

  if (c.amplitude?.apiKey) appendHead(script(amplitude(c.common, c.amplitude)));

  if (c.braze?.baseUrl && c.braze.apiKey) appendBody(script(braze(c.braze)));

  if (c.googleTagManager?.id)
    appendBody(script(googleTagManager(c.googleTagManager)));

  if (c.googleAnalytics?.propertyId && c.googleAnalytics?.conversionId) {
    const { src, content } = googleAnalytics(c.common, c.googleAnalytics);
    appendBody(scriptExternal(src));
    appendBody(script(content));
  }

  if (c.hotjar?.id) appendBody(script(hotjar(c.hotjar)));

  if (c.googleRemarketing?.conversionId) {
    const { content, fallback } = googleRemarketing(c.googleRemarketing);
    appendBody(script(content));
    appendBody(noscript(fallback));
  }

  if (c.bingAnalytics?.conversionId)
    appendBody(script(bingAnalytics(c.bingAnalytics)));

  if (c.facebookPixel?.pixelId) {
    const { content, fallback } = facebookPixel(c.common, c.facebookPixel);
    appendBody(script(content));
    appendBody(noscript(fallback));
  }

  if (c.redditPixel?.pixelId) appendBody(script(redditPixel(c.redditPixel)));

  if (c.snapPixel?.pixelId)
    appendBody(script(snapPixel(c.common, c.snapPixel)));

  if (c.tikTokPixel?.pixelId) appendBody(script(tikTokPixel(c.tikTokPixel)));

  if (c.vwo?.accountId) {
    const { loader, integrations } = vwo(c.common, c.vwo);
    appendBody(script(loader));
    if (integrations) appendBody(script(integrations));
  }

  log(`Loaded for ${c.common.product}/${c.common.region}.`);
}

if (window.analytics) bootstrapAnalytics();
if (window.workableJobs) void workableJobsLoader(window.workableJobs);
if (window.greenhouseJobs) void greenhouseJobsLoader(window.greenhouseJobs);
