import { JobsLoader } from '../../types';

const WORKABLE_JOB_DETAILS_URL =
  'https://apply.workable.com/api/v1/widget/accounts/heliosx';

const WORKABLE_JOB_HIDDEN_CODE = 'HIDE';

interface WorkableJobsData {
  jobs: WorkableJob[];
}

interface WorkableJob {
  application_url: string;
  city: string;
  code: string;
  country: string;
  created_at: string;
  education: string;
  state: string;
  title: string;
  department: string;
  url: string;
  telecommuting: boolean;
}

type DepartmentSortedJobs = Record<string, WorkableJob[]>;

const workableJobsLoader: JobsLoader = async ({ targetId, classNames }) => {
  function createDepartmentSection(department: string, jobs: WorkableJob[]) {
    const el = document.createElement('div');
    el.className = classNames.section;

    const headingEl = document.createElement('h5');
    headingEl.className = classNames.heading;
    headingEl.innerText = department;

    const jobsEl = document.createElement('div');
    jobsEl.className = classNames.jobs;

    jobs.forEach((job) => {
      const jobEl = createJobListing(job);
      jobsEl.appendChild(jobEl);
    });

    el.appendChild(headingEl);
    el.appendChild(jobsEl);
    return el;
  }

  function createJobListing(job: WorkableJob) {
    const title = sanitizeTitle(job.title);
    const el = document.createElement('a');
    el.className = classNames.job;
    el.title = title;
    el.href = job.application_url;

    const titleEl = document.createElement('div');
    titleEl.className = classNames.jobTitle;
    titleEl.innerText = title;

    const locationEl = document.createElement('span');
    locationEl.className = classNames.jobLocation;
    locationEl.innerText = buildLocationText(job);

    el.appendChild(titleEl);
    el.appendChild(locationEl);
    return el;
  }

  function sanitizeTitle(title: string) {
    return title.replace(/ [-|].*$/gi, '');
  }

  function buildLocationText({
    telecommuting,
    city,
    country,
    state,
  }: WorkableJob) {
    const isUK = country === 'United Kingdom';
    const isUS = country === 'United States';

    const remote = `Remote & Hybrid`;
    const formattedCountry = formatCountry(country);
    const formattedState = formatState(state);

    switch (true) {
      case telecommuting && isUK:
        return `${remote} - UK & Europe`;
      case telecommuting && isUS:
        return state ? `${remote} - ${formattedState}, USA` : `${remote} - USA`;
      case telecommuting && !isUK && !isUS:
        return `${remote} - ${formattedCountry}`;
      case !telecommuting && !isUS:
        return city ? `${city}, ${formattedCountry}` : formattedCountry;
      case !telecommuting && isUS:
        return state ? `${formattedState}, USA` : 'USA';
      default:
        return '';
    }
  }

  function formatCountry(country: string) {
    switch (country) {
      case 'United Kingdom':
        return 'UK';
      default:
        return country;
    }
  }

  function formatState(state: string) {
    switch (state) {
      case 'District of Columbia':
        return 'DC';
      default:
        return state;
    }
  }

  const req = await fetch(WORKABLE_JOB_DETAILS_URL);
  const data = (await req.json()) as WorkableJobsData;

  const availableJobs = data.jobs.filter(
    (job) => job.code !== WORKABLE_JOB_HIDDEN_CODE,
  );

  const byDepartment = availableJobs.reduce((out, job) => {
    const department = out[job.department] || [];

    return {
      ...out,
      [job.department]: [...department, job],
    };
  }, {} as DepartmentSortedJobs);

  const target = document.getElementById(targetId);

  if (target) {
    target.className = classNames.container;

    Object.entries(byDepartment).forEach(([department, jobs]) => {
      if (!jobs.length) return;

      const el = createDepartmentSection(department, jobs);
      target.appendChild(el);
    });
  }
};

export default workableJobsLoader;
