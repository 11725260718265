import { CommonProps, VWOProps } from '../types';

export default function vwo(common: CommonProps, { accountId }: VWOProps) {
  return {
    loader: `window._vwo_code = window._vwo_code || (function(){
    var account_id='${accountId}',
    settings_tolerance=2000,
    library_tolerance=2500,
    use_existing_jquery=false,
    is_spa=1,
    hide_element='body',

    /* DO NOT EDIT BELOW THIS LINE */
    f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){
    window.settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('//dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer; }};window._vwo_settings_timer = code.init(); return code; }());`,
    integrations: getIntegrations(common),
  };
}

function getIntegrations({ product }: CommonProps) {
  switch (product) {
    case 'dermatica':
      return `
(function() {
window.VWO = window.VWO || [];
window.integrationCallbacks = window.integrationCallbacks || [];

var identify = null,
config = {
   campaignTypesToPushDataFor: [
   'VISUAL_AB', // AB Campaign
   'VISUAL', // MVT Campaign
   'SPLIT_URL', // Split Campaign
   'SURVEY' // Survey Campaign
   ]
};

window.integrationCallbacks.push({
callback: function (campaignData, isABatchProcessed) {
   // Amplitude Integration code follows
   identify = identify || new window.amplitude.Identify();
   var amplitude = window.amplitude;

   // Once a campaign is processed
   if (campaignData) {
      identify.set('VWO-Test-ID-' + campaignData.id, campaignData.combinationName);
   } else if (isABatchProcessed) { // When all campaigns are processed
      amplitude.getInstance().identify(identify);
      amplitudeEvent('VWO', {});
      //To handle SPA, and avoid logging of events multiple times where, on url change
      //amplitude does not loses context of campaigns from previous page.
      identify = null;
   }
}, apiAvailabilityCheck: function () {
  return window.amplitude;
}});

//DO NOT CHANGE BELOW THIS LINE
var integrationCallbacks = window.integrationCallbacks,
  tryPushingDataTimer;

// Poll for the integration library API and then call the callback to send data
function checkAndIntegrate(apiAvailabilityCheck, callback, campaignMetaInfo, isABatchProcessed) {
  if (!callback) {
      throw 'callback not defined';
  }
  var timeout = 1;
  if (!apiAvailabilityCheck()) {
      clearTimeout(tryPushingDataTimer);
      tryPushingDataTimer = setTimeout(function() {
          checkAndIntegrate(apiAvailabilityCheck, callback, campaignMetaInfo, isABatchProcessed);
      }, timeout);
      return;
  }
  if (isABatchProcessed) {
      setTimeout(function() {
          callback(campaignMetaInfo, isABatchProcessed);
      }, timeout);
  } else {
      callback(campaignMetaInfo, isABatchProcessed);
  }
}

function whenABatchProcessed() {
  for (var i = 0; i < integrationCallbacks.length; i++) {
      checkAndIntegrate(integrationCallbacks[i].apiAvailabilityCheck, integrationCallbacks[i].callback, null, true);
  }
}

var debouncedWhenABatchProcessed;

window.VWO.push([
  'onVariationApplied',
  function(data) {
      try {
          var campaignId = data[1],
              campaignDetailedInfo = window._vwo_exp[campaignId],
              combinationChosen = data[2];

          var campaignMetaInfo = {
              id: data[1],
              type: campaignDetailedInfo.type,
              combinationName: campaignDetailedInfo.comb_n[combinationChosen]
          };

          debouncedWhenABatchProcessed = debouncedWhenABatchProcessed || VWO._.utils.debounce(function() {
              whenABatchProcessed();
          }, 1);

          if (
              typeof combinationChosen !== 'undefined' &&
              config.campaignTypesToPushDataFor.indexOf(campaignMetaInfo.type) > -1
          ) {
              for (var i = 0; i < integrationCallbacks.length; i++) {
                  checkAndIntegrate(integrationCallbacks[i].apiAvailabilityCheck, integrationCallbacks[i].callback, campaignMetaInfo);
              }
              debouncedWhenABatchProcessed();
          }
      } catch (e) {}
  }
]);
})();
`;
    default:
      return '';
  }
}
