import { CommonProps, SnapPixelProps } from '../types';

export default function snapPixel(
  common: CommonProps,
  snapPixel: SnapPixelProps,
) {
  return `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
  a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
  r.src=n;var u=t.getElementsByTagName(s)[0];
  u.parentNode.insertBefore(r,u);})(window,document,
  'https://sc-static.net/scevent.min.js');
${getInit(common, snapPixel)}`;
}

function getInit({ product }: CommonProps, { pixelId }: SnapPixelProps) {
  switch (product) {
    case 'dermatica':
      return `
var scItemCategory = null;
var scPageViewParams = scItemCategory ? { item_category: scItemCategory } : {};
window.history.pushState = new Proxy(window.history.pushState, {
  apply: function(target, thisArg, argArray) {
    var oldUrl = window.location.href;
    Reflect.apply(target, thisArg, argArray);
    if (oldUrl !== window.location.href) {
      snaptr('track', 'PAGE_VIEW', scPageViewParams);
    }
  }
});

var snapOpts = {};
if (typeof currentUser !== "undefined" && currentUser && currentUser.username) {
  snapOpts = {
    user_email: currentUser.username
  };
}

snaptr('init', '${pixelId}', snapOpts);
snaptr('track', 'PAGE_VIEW', scPageViewParams);
`;

    case 'medexpress':
      return `
snaptr('init', '${pixelId}', {});
snaptr('track', 'PAGE_VIEW');
    `;

    default:
      return '';
  }
}
