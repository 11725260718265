import { CommonProps, GoogleAnalyticsProps } from '../types';

export default function googleAnalytics(
  common: CommonProps,
  googleAnalytics: GoogleAnalyticsProps,
) {
  return {
    src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalytics.propertyId}`,
    content: `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
${getInit(common, googleAnalytics)}`,
  };
}

function getInit(
  { product }: CommonProps,
  { propertyId, conversionId }: GoogleAnalyticsProps,
) {
  switch (product) {
    case 'dermatica':
      return `
gtag('js', new Date());
var GA_PROPERTY = '${propertyId}';
var gtagConfig = { 'anonymize_ip': true };
if (userId != null) {
  gtag('set', {'dimension1': userId});
  gtagConfig.user_id = userId;
}
gtag('config', GA_PROPERTY, gtagConfig);
gtag('config', 'AW-${conversionId}', {'allow_enhanced_conversions': true});
`;

    case 'medexpress':
      return `
var userIdCookies = document.cookie.split(/;\\s*/)
    .map(function (x) { return x.split('='); })
    .filter(function (v) { return v[0]=='userId'; });

if (userIdCookies.length) {
  gtag('set', { 'user_id': userIdCookies[0][1] });
  gtag('set', { 'dimension4': userIdCookies[0][1] });
}

gtag('js', new Date());
gtag('config', '${propertyId}', { 'anonymize_ip': true, 'use_amp_client_id': true });
gtag('config', 'AW-${conversionId}', {
  client_map: {
    dimension5: 'clientId'
  },
  'allow_enhanced_conversions': true
});
`;
    default:
      return '';
  }
}
