import { GoogleRemarketingProps } from '../types';

export default function googleRemarketing({
  conversionId,
}: GoogleRemarketingProps) {
  return {
    content: `/* <![CDATA[ */
var google_conversion_id = '${conversionId}';
var google_custom_params = window.google_tag_params;
var google_remarketing_only = true;
/* ]]> */`,
    fallback: `<div style="display: inline;">
  <img height="1" width="1" style="border-style: none;" alt="" src="//googleads.g.doubleclick.net/pagead/viewthroughconversion/${conversionId}/?guid=ON&amp;script=0"/>
</div>`,
  };
}
